import BaseService from "./base.service";

class TemplatesService {

    templates(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('templates', page, params);
    }
    
    getTemplates(name=null) {
        return BaseService.apiGetRequest('getTemplates/'+name);
    }
    get(id) {
        return BaseService.apiGetRequest('templates/'+id);
    }
    delete(templates) {
		return BaseService.apiPostRequest('templates/delete/', templates);
    }
    add(templates) {
        return BaseService.apiPostRequest('templates/add', templates);
    }
    update(id, templates) {
        return BaseService.apiPostRequest('templates/'+id, templates);
    }
    getFilesList(templateName='default') {
        return BaseService.apiGetRequest('list-files/'+templateName);
    }
    saveFileContent(editFile) {
        if(!editFile.path) {
            return null
        }
        console.log(editFile)
        const formData = new FormData()
        formData.append('file', editFile.path)
        formData.append('content', editFile.content)
        return BaseService.apiPostRequest('update-template-file-content', formData);
    }
    getFileContent(filePath = null) {
        if(!filePath) {
            return null
        }
        const formData = new FormData()
        formData.append('file', filePath)
        return BaseService.apiPostRequest('get-template-file-content', formData);
    }
}
export default new TemplatesService();
